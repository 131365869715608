<template>
  <div>
    <sequential-entrance fromTop>
      <SellerHeader to="/seller/menu" headerText="Todos los productos" />
    </sequential-entrance>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">
            <!-- ********* Filters ********* -->
            <v-row justify="center">
              <v-col cols="12" class="pt-0 pb-0 mt-3">
                <v-text-field
                  label="Buscar producto"
                  v-model="product_filter"
                  color="#00B8D9"
                  flat
                  class="app-input pt-0"
                  @keyup="searchProductsByQuery()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0 mt-0">
                <v-select
                  v-model="category_selected"
                  :items="categoryFilter"
                  :item-value="'id'"
                  item-text="name"
                  @change="getProductsOfEvent()"
                  label="Filtrar por categoría"
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0 mt-3">
                <v-select
                  v-model="seller_selected"
                  :items="sellers_options"
                  :item-value="'id'"
                  item-text="fullname"
                  @change="getProductsOfEvent()"
                  label="Filtrar por vendedor"
                  dense
                ></v-select>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0 mt-3">
                <v-select
                  v-model="select_type"
                  :item-value="'value'"
                  :items="type_options"
                  @change="getProductsOfEvent()"
                  label="Filtrar por tipo"
                  item-text="label"
                  item-value="value"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <!-- ********* End Filters ********* -->

            <!-- ********* Skeleton Loader ******* -->
            <v-row v-if="loading">
              <v-container
                v-for="(skeleton, index) in skeletonCount"
                class="align-center d-flex justify-content-between rounded-lg mb-5"
                style="background-color: #FAFAFA!important"
                justify="between"
                :key="index"
              >
                <v-col cols="10">
                  <b-skeleton width="60%"></b-skeleton>
                  <b-skeleton width="90%"></b-skeleton>
                </v-col>

                <v-col cols="2" class="pl-0 text-center">
                  <b-skeleton type="avatar"></b-skeleton>
                </v-col>
              </v-container>
            </v-row>

            <!-- ********* Products ********* -->
            <transition name="slide-fade" v-if="!loading">
              <v-tabs grow>
                <!-- Income Tab -->
                <v-tab href="#income">
                  Ingresos ({{ incomeProducts.length }})
                </v-tab>
                <v-tab-item value="income">
                  <v-container class="px-0 app-list">
                    <v-container v-for="(product, index) in incomeProducts">
                      <!-- TEMPLATE (COMPONENTE) DE PRODUCTO -->
                      <ProductTemplate
                        :product="product"
                        :total_products_sold_type="total_products_sold_type"
                        :selectProductAndOpenModal="selectProductAndOpenModal"
                      />
                    </v-container>
                  </v-container>
                </v-tab-item>
                <!-- Income Tab -->
                <v-tab href="#food">
                  Alimentos ({{ foodProducts.length }})
                </v-tab>
                <v-tab-item value="food">
                  <v-container class="px-0 app-list">
                    <v-container v-for="(product, index) in foodProducts">
                      <!-- TEMPLATE (COMPONENTE) DE PRODUCTO -->
                      <ProductTemplate
                        :product="product"
                        :total_products_sold_type="total_products_sold_type"
                        :selectProductAndOpenModal="selectProductAndOpenModal"
                      />
                    </v-container>
                  </v-container>
                </v-tab-item>
                <!-- Drinks Tab -->
                <v-tab href="#drinks">
                  Bebidas ({{ drinkProducts.length }})
                </v-tab>
                <v-tab-item value="drinks">
                  <v-container class="px-0 app-list">
                    <v-container v-for="(product, index) in drinkProducts">
                      <!-- TEMPLATE (COMPONENTE) DE PRODUCTO -->
                      <ProductTemplate
                        :product="product"
                        :total_products_sold_type="total_products_sold_type"
                        :selectProductAndOpenModal="selectProductAndOpenModal"
                      />
                    </v-container>
                  </v-container>
                </v-tab-item>
                <!-- Others Tab -->
                <v-tab href="#other">
                  Otros ({{ otherProducts.length }})
                </v-tab>
                <v-tab-item value="other">
                  <v-container class="px-0 app-list">
                    <v-container v-for="(product, index) in otherProducts">
                      <!-- TEMPLATE (COMPONENTE) DE PRODUCTO -->
                      <ProductTemplate
                        :product="product"
                        :total_products_sold_type="total_products_sold_type"
                        :selectProductAndOpenModal="selectProductAndOpenModal"
                      />
                    </v-container>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </transition>
            <!-- ********* End Products ********* -->
          </v-col>
        </v-row>

        <!-- ********* Paginator ********* -->
        <v-row class="mb-5" v-if="total_pages > 1">
          <v-pagination
            style="margin: auto"
            v-model="page"
            :length="total_pages"
          ></v-pagination>
        </v-row>
        <!-- ********* End Paginator ********* -->

        <!-- ********* Create product button ********* -->
        <!-- Mobile -->
        <v-fab-transition>
          <v-btn
            class="d-block d-sm-none"
            color="#7f6cff"
            dark
            fixed
            bottom
            right
            fab
            @click="createProduct()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>

        <!-- Desktop -->
        <v-fab-transition>
          <v-btn
            class="d-none d-sm-block"
            color="#7f6cff"
            dark
            absolute
            bottom
            right
            fab
            @click="createProduct()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-container>

      <!-- ************************** Modals ***********************-->

      <!-- Clone Modal -->
      <b-modal
        ref="modal-clone"
        id="modal-clone"
        centered
        title="Clonar producto"
        header-bg-variant="dark"
        header-text-variant="light"
        hide-footer
        v-if="product"
      >
        <div class="text-center">
          <p class="font-italic">
            ¿Estás seguro que deseas clonar el producto
            <strong>{{ product.name }}</strong
            >?
          </p>
          <b-button
            class="mx-3"
            variant="success"
            @click="
              cloneProduct(product.id)
              $bvModal.hide(`modal-clone`)
            "
            >Clonar producto</b-button
          >
        </div>
      </b-modal>

      <!-- Generate Anonymys tickets Modal -->

      <b-modal
        ref="generate-anonymus-modal"
        id="generate-anonymus-modal"
        centered
        title="Generar tickets anónimos"
        header-bg-variant="dark"
        header-text-variant="light"
        hide-footer
        v-if="product"
      >
        <div class="text-center">
          <p class="font-italic">
            <strong>{{ product.name }}</strong>
          </p>
          <b-form-group label="Tickets a generar">
            <b-form-input
              type="number"
              v-model="quantityTicketPDF"
              :max="product.quota - product.total_tickets_active"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Selecciona un vendedor">
            <b-form-select
              v-model="selectedOptionTicketPDF"
              :options="sellersOptionsForProduct"
              text-field="fullname"
              value-field="id"
            ></b-form-select>
          </b-form-group>

          <b-button
            class="mx-3"
            variant="success"
            @click="
              generateTicketAnonymus(product.id)
              $bvModal.hide(`generate-anonymus-modal`)
            "
            >Generar PDF</b-button
          >
        </div>
      </b-modal>

      <!-- Disable product Modal -->
      <b-modal
        ref="disable-modal"
        id="disable-modal"
        centered
        title="Deshabilitar producto"
        header-bg-variant="dark"
        header-text-variant="light"
        hide-footer
        v-if="product"
      >
        <div class="text-center">
          <p class="font-italic">
            ¿Estás seguro que deseas deshabilitar el producto
            <strong>{{ product.name }}</strong
            >?
          </p>
          <p>
            La capacidad máxima pasará de ser {{ product.max_quote }} cupos a
            <strong>{{ product.total_tickets_active }} cupos</strong>
          </p>
          <b-button
            class="mx-3"
            variant="success"
            @click="
            disableProduct(product.id)
              $bvModal.hide(`disable-modal`)
            "
            >Deshabilitar producto</b-button
          >
        </div>
      </b-modal>

      <!-- Active/Inactive Modal -->
      <b-modal
        ref="modal-active-inactive"
        id="modal-active-inactive"
        centered
        title="Activar/desactivar producto"
        header-bg-variant="dark"
        header-text-variant="light"
        hide-footer
        v-if="product"
      >
        <div class="text-center">
          <!-- Si el producto está activo -->
          <div v-if="product.active">
            <!-- Si tiene Tickets -->
            <div v-if="!product.total_tickets_active">
              <p class="font-italic">
                ¿Estás seguro que deseas desactivar el producto
                <strong>{{ product.name }}</strong
                >? Si se desactiva no se podrá comercializar.
              </p>
              <b-button
                class="mx-3"
                variant="success"
                @click="
                  setProductActive(product.id, false)
                  $bvModal.hide(`modal-active-inactive`)
                "
                >Desactivar producto
              </b-button>
            </div>

            <!-- Si NO tiene Tickets -->
            <p class="font-italic" v-if="product.total_tickets_active">
              El producto <strong>{{ product.name }}</strong> no se puede
              desactivar ya que tiene tickets vendidos.
            </p>
          </div>

          <!-- Si el producto está inactivo -->
          <div v-if="!product.active">
            <p class="font-italic" v-if="!product.active">
              ¿Estás seguro que deseas activar el producto
              <strong>{{ product.name }}</strong
              >?
            </p>
            <b-button
              class="mx-3"
              variant="success"
              @click="
                setProductActive(product.id, true)
                $bvModal.hide(`modal-active-inactive`)
              "
              >Activar producto
            </b-button>
          </div>
        </div>
      </b-modal>

      <!-- History Changes Modal -->
      <b-modal
        ref="modal-history-changes"
        id="modal-history-changes"
        centered
        :title="'Historial de cambios: Producto ' + product.name"
        header-bg-variant="dark"
        header-text-variant="light"
        hide-footer
        v-if="product"
      >
        <div class="accordion" role="tablist">
          <p
            class="text-center"
            v-if="productHistoryChanges && !productHistoryChanges.length"
          >
            No se registran cambios para este producto.
          </p>

          <b-card
            no-body
            class="mb-1"
            v-for="(log, index) in productHistoryChanges"
            :key="index"
          >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'collapse' + index" variant="info"
                >Cambio en: {{ log.field_changed }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="'collapse' + index"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text
                  ><strong>Usuario: </strong
                  >{{ log.user.id + ' - ' + log.user.fullname }}</b-card-text
                >
                <b-card-text
                  ><strong>Fecha: </strong
                  >{{ idoDateToDateAndHours(log.created_at) }}</b-card-text
                >

                <div v-if="log.field_changed != 'Vendedores'">
                  <b-card-text
                    ><strong>Valor anterior: </strong
                    >{{ log.old_value }}</b-card-text
                  >
                  <b-card-text
                    ><strong>Valor nuevo: </strong
                    >{{ log.new_value }}</b-card-text
                  >
                </div>

                <div v-else>
                  <b-card-text v-if="log.addedSellers?.length"
                    ><strong>Vendedores Agregados: </strong
                    ><span style="color: green;">{{
                      log.addedSellers.join(', ')
                    }}</span></b-card-text
                  >
                  <b-card-text v-if="log.removedSellers?.length"
                    ><strong>Vendedores Quitados: </strong
                    ><span style="color: red;">{{
                      log.removedSellers.join(', ')
                    }}</span></b-card-text
                  >
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-modal>

      <!-- Disable product Modal -->
      <b-modal
        ref="modal-delete-product"
        id="modal-delete-product"
        centered
        title="Eliminar producto"
        header-bg-variant="dark"
        header-text-variant="light"
        hide-footer
        v-if="product"
      >
        <div class="text-center">
          <p class="font-italic">
            ¿Estás seguro que deseas eliminar el producto
            <strong>{{ product.name }}</strong
            >?
          </p>
          <b-button
            v-if="!deleteLoading"
            class="mx-3"
            variant="danger"
            @click="deleteProduct(product.id)"
            >Eliminar producto</b-button
          >
          <b-skeleton v-if="deleteLoading"></b-skeleton>
        </div>
      </b-modal>
      <!-- ************************** END Modals ***********************-->
    </v-main>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getCategoriesBySeller,
  getProductsOfEvent,
  updateProductStatus,
  getHistoryChangesOfProduct,
  getSellersOfEvent,
} from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import {
  cloneProduct,
  disableProduct,
  getSellerByProduct,
  generateTicketAnonymus,
  deleteProduct,
} from '@/services/seller.service'
import { idoDateToDateAndHours } from '@/helpers/date'
import _ from 'lodash'
import ProductTemplate from '@/components/Seller/ProductTemplate.vue'

export default {
  name: 'SellerCategoryList',

  data() {
    return {
      filter: null,
      categories: [],
      allProducts: [],
      product: null,
      editProductDisabled: null,
      skeletonCount: [0, 1, 2, 3],
      loading: false,
      deleteLoading: false,
      total_products_sold_type: null,
      product_filter: null,
      category_selected: null,
      page: 1,
      total_pages: 1,
      productHistoryChanges: null,
      sellers_options: null,
      seller_selected: null,
      select_type: null,
      type_options: [
        { label: 'Todos', value: 'both' },
        { label: 'Link', value: 0 },
        { label: 'Ecommerce', value: 1 },
        { label: 'VIP', value: 'vip' },
      ],
      timeout: null,
      quantityTicketPDF: 1,
      sellersOptionsForProduct: [],
      selectedOptionTicketPDF: {},
    }
  },
  computed: {
    categoryFilter() {
      return this.categories.filter(category =>
        this.filter
          ? category?.name.toLowerCase().includes(this.filter.toLowerCase())
          : category
      )
    },
    orderedProducts: function() {
      return _.orderBy(this.allProducts, 'id', 'desc')
    },
    incomeProducts: function() {
      return _.filter(this.allProducts, { product_type: 'income' })
    },
    foodProducts: function() {
      return _.filter(this.allProducts, { product_type: 'food' })
    },
    drinkProducts: function() {
      return _.filter(this.allProducts, { product_type: 'drinks' })
    },
    otherProducts: function() {
      return _.filter(this.allProducts, { product_type: 'others' })
    },
  },
  methods: {
    goToProductCategoryId({ id: categoryId }) {
      localStorage.setItem('ticket-qr-category', categoryId)

      this.$router.push({
        name: 'Mis productos',
        params: { categoryId },
      })
    },
    showModal() {
      this.$refs['clone-modal'].show()
    },
    // Seleccionar producto para que la variable sea global, y asi no tener que invocar 1 modal oculto por cada producto (como estaba antes)
    async selectProductAndOpenModal(product, modal) {
      this.product = product

      if (modal == 'modal-history-changes')
        await this.getHistoryChangesOfProduct(product)
      else if (modal == 'generate-anonymus-modal') {
        const sellers = await getSellerByProduct(product.id)
        this.sellersOptionsForProduct = sellers
        console.log(sellers)
      }

      setTimeout(() => {
        this.$bvModal.show(modal)
      })
    },
    async getHistoryChangesOfProduct(product) {
      const response = await getHistoryChangesOfProduct(product.id)
      this.productHistoryChanges = response.data.data
    },
    async setProductActive(product_id, value) {
      const response = await updateProductStatus(product_id, value)

      if (response.status == '200') {
        Vue.$toast.success(
          `Producto ${value ? 'activado' : 'desactivado'} correctamente!`
        )
        this.getProductsOfEvent()
      } else {
        Vue.$toast.error('Ha ocurrido un error actualizando el producto')
      }
    },
    async cloneProduct(id) {
      const response = await cloneProduct(id)

      if (response.status == '200') {
        Vue.$toast.success(`Producto clonado correctamente!`)
        this.getProductsOfEvent()
      } else {
        Vue.$toast.error('Ha ocurrido un error clonando el producto')
      }
    },
    async disableProduct(id) {
      const response = await disableProduct(id)

      if (response.status == '200') {
        Vue.$toast.success(`Producto deshabilitado correctamente!`)
        this.getProductsOfEvent()
      } else {
        Vue.$toast.error('Ha ocurrido un error deshabilitando el producto')
      }
    },

    async generateTicketAnonymus(id) {
      Vue.$toast.info(`Generando tickets, espere un momento...`)

      if (
        parseFloat(this.quantityTicketPDF) >
        this.product.max_quote - this.product.total_tickets_active
      ) {
        Vue.$toast.error(
          `La cantidad de tickets a generar no puede ser mayor a la cantidad de cupos disponibles de ${this
            .product.max_quote - this.product.total_tickets_active}`
        )
        return
      }

      if (parseFloat(this.quantityTicketPDF) < 1) {
        Vue.$toast.error(
          `La cantidad de tickets a generar no puede ser menor a 1`
        )
        return
      }

      console.log(id, this.quantityTicketPDF, this.selectedOptionTicketPDF)

      const response = await generateTicketAnonymus(
        id,
        parseFloat(this.quantityTicketPDF),
        this.selectedOptionTicketPDF
      )

      if (response) {
        Vue.$toast.success(`Tickets generados correctamente!`)
        window.open(response, '_blank')
      } else {
        Vue.$toast.error('Ha ocurrido un error generando los tickets')
      }

      /* if (response.status == '200') {
        Vue.$toast.success(`Tickets generados correctamente!`)
        this.getProductsOfEvent()
      } else {
        Vue.$toast.error('Ha ocurrido un error generando los tickets')
      } */
    },

    async getCategories() {
      try {
        const category = localStorage.getItem('selectedEvent')
        const data = await getCategoriesBySeller(category)

        this.categories = data.data

        this.categories.unshift({
          name: 'Todos los productos',
          id: '',
        })
      } catch (error) {
        this.$toast.error(ErrorMessages.COMMON)
      }
    },
    async getProductsOfEvent() {
      try {
        this.loading = true

        const eventId = localStorage.getItem('selectedEvent')
        const { data, meta, configs } = await getProductsOfEvent(
          eventId,
          this.page,
          this.product_filter,
          this.category_selected,
          this.seller_selected,
          this.select_type
        )

        this.total_products_sold_type = configs.total_products_sold_type
          ? configs.total_products_sold_type == 'hidden'
            ? 'nominal'
            : configs.total_products_sold_type
          : false

        this.allProducts = data

        this.total_pages = meta.last_page

        this.loading = false
      } catch (e) {
        this.$toast.error(ErrorMessages.COMMON)
        console.error(e)
      }
    },
    async searchProductsByQuery() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getProductsOfEvent();
      }, 800);
    },
    async getSellersOfEvent() {
      try {
        this.sellers_options = await getSellersOfEvent(
          localStorage.getItem('selectedEvent')
        )

        this.sellers_options = [
          { id: '', fullname: 'Todos' },
          ...this.sellers_options,
        ]
      } catch (error) {
        Vue.$toast.error(ErrorMessages.COMMON)
      }
    },
    idoDateToDateAndHours(date) {
      return idoDateToDateAndHours(date)
    },
    createProduct() {
      const eventId = localStorage.getItem('selectedEvent')
      window.location.href =
        process.env.VUE_APP_ADMIN_DOMAIN +
        '/products/create/' +
        eventId +
        '?jwt=' +
        localStorage.getItem('access_token')
    },
    async deleteProduct(productId) {
      try {
        this.deleteLoading = true
        const response = await deleteProduct(productId)
        Vue.$toast.success(response.data.message)
        await this.getProductsOfEvent()
      } catch (e) {
        Vue.$toast.error(e.response.data.message)
      } finally {
        this.$refs['modal-delete-product'].hide()
        this.deleteLoading = false
      }
    },
  },
  watch: {
    page(nextPage, _currentPage) {
      this.page = nextPage
      this.getProductsOfEvent()
    },
  },
  components: {
    ProductTemplate,
    SellerHeader,
  },
  async mounted() {
    this.loading = true
    await this.getProductsOfEvent()
    await this.getCategories()
    await this.getSellersOfEvent()
    this.loading = false
  },
}
</script>

<style lang="scss">
.v-slide-group__prev {
  display: none !important;
}
.app-list {
  min-height: 700px;
}
</style>
